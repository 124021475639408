import { h } from 'preact';

function ProfileImage({ src, alt }) {
    return src ? <img src={src} alt={alt} /> : null;
}

function Rating({ rating }) {
    return rating ? (
        <>
            <span className="dot-separator">·</span>
            <span>Rating: {rating.label}</span>
        </>
    ) : null;
}

export function StorySummary({ hit, style = 'list', storyTrackingEvent = 'clicked', userTrackingEvent = 'converted' }) {
    console.log('story summary style = ', style);
    // Define conditional classes
    const wrapperClass = style === 'list' ? 'border-b border-gray-200 mb-8 pb-6' : 'col-span-full pb-2 md:col-span-1';

    const profileImage = hit.author.profileImage?.[100] ?? null;

    return (
        <div className={`${wrapperClass}`}>
            <div>
                <div className="flex flex-row h-full relative">
                    <div>
                        <div className="mb-1">
                            <a className="group mb-2 table text-gray-700 items-center" href={hit.author.url} aria-label={`All stories by ${hit.author.fullname}`} data-track-click={`u;${hit.author.id};${userTrackingEvent}`}>
                                <div className="flex leading-tight items-center">
                                    <div className="bg-gray-200 border overflow-hidden rounded-full shadow-profile shrink-0 inline-block mr-2 h-6 w-6">
                                        <ProfileImage src={profileImage} alt={hit.author.fullname} />
                                    </div>

                                    <span className="font-bold text-slight group-hover:underline">{hit.author.fullname}</span>
                                </div>
                            </a>
                        </div>

                        <h2 className="type-h3 font-sans mb-3">
                            <a className="break-words hyphens-auto overflow-hidden" href={hit.url} aria-label={`Read more: ${hit.title} by ${hit.author.fullname}`} data-track-click={`s;${hit.objectID};${storyTrackingEvent}`}>{hit.title}</a>
                        </h2>

                        <div className="text-base">
                            <p>{hit.summary}</p>
                        </div>

                        <div className="flex items-center mt-3 text-gray-600 text-slight">
                            <a className="whitespace-nowrap hover:underline" href={`/time/${hit.readTimeSearch}`} aria-label={`Find stories around ${hit.readTime} long`}>{hit.readTimeMin}</a>

                            <Rating rating={hit.rating} />

                            <span className="dot-separator">·</span>
                            <a className="whitespace-nowrap hover:underline" href={hit.url} aria-label={`Read more: ${hit.title} by ${hit.author.fullname}`} data-track-click={`s;${hit.objectID};${storyTrackingEvent}`}>Read story</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const StorySummaryColumnsWrapper = ({ children, classNames }) => (
    <div className={`gap-10 grid grid-cols-2 mb-14 ${classNames}`}>
        {children}
    </div>
);

export function StoryOverview({ hit, storyTrackingEvent = '', userTrackingEvent = '' }) {
    const profileImage = hit.author.profileImage?.[100] ?? null;

    return (
        <div className="mb-3">
            <div className="">
                <a className="group mb-2 table text-gray-700 items-center" href={hit.author.url} title={`All stories by ${hit.author.fullname}`} data-track-click={`u;${hit.author.id};${userTrackingEvent}`}>
                    <div className="flex leading-tight items-center">
                        <div className="bg-gray-200 border overflow-hidden rounded-full shadow-profile shrink-0 inline-block mr-1 h-6 w-6">
                            <ProfileImage src={profileImage} alt={hit.author.fullname} />
                        </div>

                        <span className="text-xs group-hover:underline">{hit.author.fullname}</span>
                    </div>
                </a>
            </div>

            <h3 className="type-h6 leading-tight font-sans font-bold">
                <a className="" href={hit.url} title={`Read ${hit.title} by ${hit.author.fullname}`} rel="noopener follow" data-track-click={`s;${hit.objectID};${storyTrackingEvent}`}>{hit.title}</a>
            </h3>

            <div className="flex items-center -mt-1 text-gray-600 text-xs">
                <a className="whitespace-nowrap hover:underline" href={`/time/${hit.readTimeSearch}`} title={`Find stories around ${hit.readTime} long`}>{hit.readTimeMin}</a>

                <Rating rating={hit.rating} />
            </div>
        </div>
    );
}
